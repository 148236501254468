.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form {
        width: 400px;

        :global {
            .ant-input-affix-wrapper {
                border-radius: 8px;
                background-color: #f7f8fb;
                padding: 11px 16px 11px 24px;

                &:hover {
                    border-color: var(--pc-primary-color);
                }
            }

            .ant-input-group-addon {
                border: none;
                background-color: #f7f8fb;
                padding: 4px 0 4px 24px;
            }
        }

        input {
            outline: none;
            color: #262b2e;
            background-color: #f7f8fb;

            &::placeholder {
                color: #999;
            }
        }
    }

    .mobileInput {
        border: solid 1px #ebebeb;
        border-radius: 8px;

        &:hover {
            border-color: var(--pc-primary-color);
            box-shadow: 0 0 0 2px rgba(5, 255, 175, 0.1);
        }

        :global {
            .ant-select-selector {
                border: none !important;
                box-shadow: none !important;
            }

            .ant-select-selection-item {
                color: #262b2e;
                margin-top: 1px;
            }
        }

        svg {
            color: #262b2e;
        }

        input {
            box-shadow: none;
            outline: none !important;
            background-color: #f7f8fb;

            &:focus,
            &:hover {
                border: none;
                box-shadow: none;
                background-color: #f7f8fb;
            }
        }
    }

    .prefix {
        display: flex;
        align-items: center;
        color: #262b2e;
        font-size: 16px;

        i {
            margin-right: 4px;
        }
    }

    .mobilePrefix {
        display: flex;
        align-items: center;

        i {
            margin-right: 4px;
            color: #262b2e;
        }
    }

    .botMargin {
        margin-bottom: 0;
    }

    .btn {
        border-radius: 4px !important;
        margin-top: 36px;
        color: #262f2d;

        &:disabled {
            color: #fff;
            background-color: #ccc;
        }
    }

    .tip {
        color: #999;
        font-size: 14px;
        margin-top: 24px;
        margin-bottom: 8px;
        text-align: center;
    }

    .botTip {
        display: none;
        width: 100%;
        margin: 0;
        text-align: center;
        font-size: 12px;
        color: #999;
        position: fixed;
        bottom: 22px;
        left: 0;
        padding: 0 24px;
        box-sizing: border-box;
    }

    .pwdLogin {
        cursor: pointer;
        color: var(--pc-primary-color);
        text-align: right;
    }

    .pwdBtns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
    }

    .pwd {
        svg {
            font-size: 20px;
        }
    }
}

@media screen and (max-width: 628px) {
    .mobile {

        .form {
            width: 100%;
            padding: 0 24px;
            box-sizing: border-box;

            :global {
                .ant-form-item {
                    margin-bottom: 32px;
                }

                .ant-input-prefix {
                    margin-right: 0;
                }

                .ant-input-affix-wrapper {
                    height: 48px;
                    border: 0;
                    border-radius: 24px;
                    padding: 11px 16px;
                    background-color: #f5f9f9;
                }

                .ant-form-item-explain-error {
                    padding: 0 16px;
                }

                .ant-input-group-addon {
                    padding-left: 16px;
                }

                .ant-input-group-addon {
                    border-radius: 24px;
                }
            }

            input {
                border-radius: 24px;
                font-size: 14px !important;
                background-color: #f5f9f9;
            }
        }

        .mobileInput {
            line-height: normal;
            border-radius: 24px;
            border: none;

            i {
                display: none;
            }

            :global {
                .ant-select-selector {
                    border: none !important;
                    box-shadow: none !important;
                }

                .ant-select-selection-item {
                    color: #262b2e;
                    margin-top: 1px;
                }
            }

            input {
                height: 46px;
                box-shadow: none;
                outline: none !important;
                background-color: #f5f9f9;

                &:focus,
                &:hover {
                    border: none;
                    box-shadow: none;
                    background-color: #f5f9f9;
                }
            }
        }

        .prefix {
            display: none;
        }

        .mobilePrefix {
            display: flex;
            align-items: center;

            i {
                margin-right: 4px;
                color: #262b2e;
            }
        }

        .btn {
            border-radius: 24px !important;
            margin-top: 40px;

            &:disabled {
                color: #999999;
                background-color: #ebebeb;
            }
        }

        .tip {
            display: none;
        }

        .botTip {
            display: block;
        }


        .pwdLogin {
            padding: 0 16px;
        }

        .pwd {
            svg {
                font-size: 16px;
            }
        }
    }
}