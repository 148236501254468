.mobile {
	display: flex;
	flex-direction: column;
	align-items: center;

	.form {
		width: 400px;

		:global {
			.ant-input-affix-wrapper {
				border-radius: 8px;
				background-color: #f7f8fb;
				padding: 11px 16px 11px 24px;

				&:hover {
					border-color: var(--pc-primary-color);
				}
			}

			.ant-input-group-addon {
				border: none;
				background-color: #f7f8fb;
				padding: 4px 0 4px 24px;
			}
		}

		input {
			background-color: #f7f8fb;

			&::placeholder {
				color: #999;
			}
		}
	}

	.prefix {
		display: flex;
		align-items: center;
		color: #262b2e;
		font-size: 16px;

		i {
			margin-right: 4px;
		}
	}

	.btn {
		border-radius: 4px !important;
		margin-top: 36px;
		margin-bottom: 54px;
		color: #262f2d;

		&:disabled {
			color: #fff;
			background-color: #ccc;
		}
	}

	.pwdLogin {
		cursor: pointer;
		color: var(--pc-primary-color);
		text-align: right;
	}

	.pwdBtns {
		display: flex;
		align-items: center;
		justify-content: space-between;
		user-select: none;
	}

	.pwd {
		svg {
			font-size: 20px;
		}
	}
}

@media screen and (max-width: 628px) {
	.mobile {

		.form {
			width: 100%;
			padding: 0 24px;
			box-sizing: border-box;

			:global {
				.ant-form-item {
					margin-bottom: 32px;
				}

				.ant-input-prefix {
					margin-right: 0;
				}

				.ant-input-affix-wrapper {
					height: 48px;
					border: 0;
					border-radius: 24px;
					padding: 11px 16px;
					background-color: #f5f9f9;
				}

				.ant-form-item-explain-error {
					padding: 0 16px;
				}
			}

			input {
				font-size: 14px !important;
				background-color: #f5f9f9;
			}
		}

		.prefix {
			display: none;
		}

		.botMargin {
			margin-bottom: 0;
		}

		.btn {
			border-radius: 24px !important;
			margin-top: 40px;

			&:disabled {
				color: #999999;
				background-color: #ebebeb;
			}
		}

		.pwdLogin {
			padding: 0 16px;
		}

		.pwd {
			svg {
				font-size: 16px;
			}
		}
	}
}