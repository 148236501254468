.wrapper {
    height: 100vh;
    position: relative;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }

    input[type='number'] {
        appearance: textfield;
        -moz-appearance: textfield;
    }
}

.wrapperPc {
    display: block;

    .login {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .back {
        position: absolute;
        top: 25px;
        left: 25%;
        z-index: 1;
        transform: translateX(-50%);
        font-size: 18px;
        color: var(--pc-normal-color);
        display: flex;
        align-items: center;

        &:hover {
            color: var(--theme-color) !important;
        }
        s img {
            width: 14px;
            margin-top: 10px;
        }
    }

    .thirdParties {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;

        .thirdLogo {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border: 1px solid #ebebeb;
            border-radius: 50%;
            margin-right: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:last-child {
                margin-right: 0;
            }

            img {
                object-fit: contain;
            }
        }
    }

    .tabs {
        margin-top: 48px;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        :global {
            .ant-tabs-nav {
                width: 400px;

                &::before {
                    display: none;
                }
            }

            .ant-tabs-ink-bar {
                display: none;
            }

            .ant-tabs-tab {
                padding: 0 0 4px;
            }

            .ant-tabs-tab-active {
                position: relative;

                &::after {
                    content: ' ';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 18px;
                    height: 2px;
                    background-color: var(--theme-color);
                }
            }
        }
    }
}

.wrapperMobile {
    display: none;
    height: 100%;

    .login {
        position: static;
        padding-top: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tabs {
        width: 100%;
        margin-top: 48px;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        :global {
            .ant-tabs-nav {
                width: 100%;
                margin-bottom: 32px;

                &::before {
                    display: none;
                }
            }

            .ant-tabs-nav-wrap {
                justify-content: center;
            }

            .ant-tabs-ink-bar {
                display: none;
            }

            .ant-tabs-tab {
                padding: 0 0 6px;
            }

            .ant-tabs-tab-btn {
                font-size: 16px;
                font-weight: 500;
                font-family: PingFangSC-Medium;
                letter-spacing: 0.5px;
                line-height: normal;
                color: #666666;
            }

            .ant-tabs-tab-active {
                position: relative;

                .ant-tabs-tab-btn {
                    color: #010b27 !important;
                }

                &::after {
                    content: ' ';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 18px;
                    height: 2px;
                    background-color: var(--theme-color);
                }
            }

            .ant-tabs-content-holder {
                width: 100%;
            }
        }
    }
}

.pageLoading {
    position: fixed !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
    .wrapperPc {
        .back {
            left: 24px;
            transform: translateX(0);
        }
    }
}

@media screen and (max-width: 628px) {
    .wrapperPc {
        display: none;
    }

    .wrapperMobile {
        display: block;
    }
}
