.codeMenu {
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    box-sizing: border-box;
    background-color: var(--pc-base-background);
    z-index: 1000;
    transition: all 0.2s;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
    display: none;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: var(--pc-normal-hover-color);
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
}

.codeMenuOpen {
    opacity: 1;
    top: 0;
    display: block;
}

.searchInputBox {
    padding: 16px 24px;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1;
    line-height: normal;

    .searchInput {
        padding: 0 16px !important;
        height: 32px !important;
        border-radius: 20px;
        border: solid 1px #ebebeb;
        background-color: #f7f8fb;
        caret-color: #00e0b9;
        box-sizing: border-box;

        input {
            height: 100% !important;
            background-color: transparent;
        }

        .prefix {
            color: #999999;
            margin-right: 6px;
            font-size: 14px;
        }

        :global {
            .ant-input-prefix {
                margin-right: 8px;
            }

            .ant-input-affix-wrapper {
                padding: 0;
            }
        }

        .closeIcon {
            cursor: pointer;
            width: 16px;
            height: 16px;
            display: block;
            font-size: 16px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #cccccc;
            position: relative;
            top: 1px;
        }
    }
}

.groupList {
    .listGroupTit {
        height: 24px;
        line-height: 24px;
        padding: 0 16px;
        background-color: #f7f7f7;
        font-weight: 500;
        font-family: PingFangSC-Medium;
        letter-spacing: 0.5px;
        color: #999999;
    }

    .listGroupItem {
        min-height: 48px;
        padding: 8px 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &::after {
            position: absolute;
            bottom: 0;
            left: 16px;
            content: '';
            width: calc(100% - 32px);
            height: 1px;
            background-color: #f7f7f7;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

        span:first-child {
            width: calc(100% - 80px);
            white-space: pre-wrap;
            font-weight: 500;
            font-family: PingFangSC-Medium;
            letter-spacing: 0.5px;
            color: #010b27;
        }

        span:last-child {
            font-family: 'D-DIN';
            letter-spacing: 0.5px;
            color: #999999;
        }

        &.listGroupActive {
            span {
                color: var(--pc-primary-color);
            }
        }
    }
}

.nullData {
    width: 200px;
    margin: 40px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .nullTxt {
        margin-top: 16px;
        font-size: 12px;
        color: #666666;
    }
}
