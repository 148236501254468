.codeSelect {
    height: 48px;
    min-width: 70px;
    box-sizing: border-box;

    .suffix {
        font-size: 18px;
        color: var(--color-text-explain);
    }

    :global {
        .ant-select-dropdown {
            left: -1px !important;
            top: 54px !important;
            overflow: visible;
            background-color: transparent !important;
            box-shadow: none;
        }

        .ant-select-selector {
            width: 100%;

            .ant-select-selection-item {
                height: 48px;
                text-align: left;
                overflow: visible;
                color: var(--color-text-explain);
            }
        }
    }
}

.mobileVal {
    display: none;
    min-width: 54px;
    padding-right: 10px;
    box-sizing: border-box;
    height: 14px;
    color: #010b27;
    line-height: 14px;
    border-right: 1px solid #010b27;

    span {
        color: #010b27;
    }

    span:first-child {
        margin-right: 8px;
    }
}

.popupClass {
    padding: 0 !important;
    border-radius: 0 !important;
    left: -1px !important;

    .selOption {
        .text {
            line-height: 1.5;
            letter-spacing: 0.5px;
            color: #666666;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
        }

        &.selOptionBorder {
            border-bottom: none !important;
        }
    }

    .selectDropdown {
        width: 400px;
        text-align: left;
        background-color: transparent;
        padding-top: 8px;
        filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.18));

        .selectDropdownBack {
            position: relative;
            background-color: #ffffff;
            border-radius: 4px;
        }

        .triangle {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 8px solid #ffffff;
            position: absolute;
            top: -8px;
            left: 40px;
        }

        .searchInput {
            width: 100%;
            height: 48px;
            border: none;
            border-radius: 0;
            border: none;
            color: #999999;
            caret-color: #00e0b9;
            padding: 0 24px !important;
            background-color: transparent !important;
            position: relative;
            // z-index: 1;

            input {
                font-size: 14px !important;
                background-color: transparent !important;
                letter-spacing: 0.5px;

                &:focus {
                    box-shadow: inset 0 0 0 48px #fff !important;
                }
            }

            .prefix {
                color: #999999;
            }

            :global {
                .ant-input-prefix {
                    margin-right: 8px;
                }

                .ant-input-affix-wrapper {
                    padding: 0;
                }
            }
        }

        .listBox {
            position: relative;
        }

        .aHref {
            width: 13px;
            height: calc(100% - 8px);
            position: absolute;
            right: 0;
            top: 5px;
            overflow-y: scroll;
            overflow-x: hidden;
            scrollbar-width: none;
            -ms-overflow-style: none;
            touch-action: none;
            line-height: 140%;

            p {
                font-size: 12px;
                color: #999999;
                cursor: pointer;

                &:hover {
                    color: #05e8c1;
                }
            }

            &::-webkit-scrollbar {
                display: none !important;
            }
        }

        :global {
            .rc-virtual-list {
                .ant-select-item-option {
                    width: calc(100% - 32px);
                    height: 48px;
                    line-height: 48px;
                    letter-spacing: 0.5px;
                    margin: 0 16px;
                    box-sizing: border-box;
                    padding: 0;
                    border-radius: 0;
                    text-align: left;
                    border-bottom: 1px solid #ebebeb;
                }

                .ant-select-item-group {
                    height: 16px;
                    line-height: 16px;
                    min-height: 0;
                    padding: 0 16px;
                    font-size: 12px;
                    color: #999999;
                    background-color: #fbfbfb;
                    border-radius: 0;
                }

                .ant-select-item-option-active {
                    background-color: transparent;

                    &:hover {
                        span {
                            color: #05e8c1;
                        }
                    }
                }

                .ant-select-item-option-selected {
                    background-color: transparent;

                    span {
                        color: #05e8c1;
                        font-weight: normal;
                    }
                }

                .rc-virtual-list-scrollbar {
                    width: 4px !important;
                    right: 16px !important;
                }
            }
        }
    }
}

.nullData {
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .nullTxt {
        margin-top: 8px;
        font-size: 14px;
        color: #666666;
    }

    :global {
        .ant-image {
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 628px) {
    .codeSelect {
        display: none !important;
    }

    .mobileVal {
        display: block;
    }
}
